@use '../../Partials/variables' as *;
@use '../../Partials/mixins' as *;

.backtotop {
    @include flex-column;
    @include center;
    text-align: center;
    padding: 1rem;
    background-color: transparent;

    &__button {
        display: flex;
        @include center;
        color: $primary-color;
        padding: 0;
        font-size: 0.75rem;
        border: 1px solid $primary-color;
        border-radius: 50%;
        width: 3rem;
        height: 3rem;
        margin-bottom: 0.5rem;
        transition: all 0.5s $base-ease;

        &:hover {
            color: $primary-color;
            transform: scale(1.1);
        }
    }

    &__text {
        font-size: 0.75rem;
        user-select: none;
    }
}
// scss file containing most styles for text on the page

@use './variables' as *;

// text styles

// large size header
@mixin hh {
  font-size: 3.5rem;
  line-height: 3.5rem;
  font-weight: 700;
}

// standard header
@mixin h {
  font-size: 2.75rem;
  line-height: 2.75rem;
  font-weight: 500;
}

// subheader
@mixin sh {
  font-size: 2rem;
  line-height: 2rem;
  font-weight: 300;
}

// standard text
@mixin p {
  font-size: 1.27rem;
  line-height: 1.5rem;
  word-spacing: 0.1rem;
  font-weight: 400;
}


@mixin contact-info {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

// specific styling for label tags
@mixin label {
  font-size: 0.75rem;
  line-height: 1.25rem;
  color: $label-color;
  font-weight: 400;
}

// mobile styles
@mixin h-mobile {
  font-size: 2rem;
  line-height: 2rem;
  font-weight: 500;
}

@mixin sh-mobile {
  font-size: 1.5rem;
  line-height: 1.375rem;
  font-weight: 300;
}

@mixin p-mobile {
  font-size: 0.8125rem;
  line-height: 1.125rem;
}

// overall text and component styles that change depending on the screen size
@mixin hero-header {
  @include h-mobile;

  @include tablet {
    @include h;
  }

  @include large-tablet {
    @include hh;
  }
}

@mixin header {
  @include h-mobile;

  @include tablet {
    @include h;
  }
}

@mixin subheader {
  @include sh-mobile;

  @include tablet {
    @include sh;
  }
}

@mixin large-paragraph {
  @include p-mobile;

  @include tablet {
    @include p;
  }

  @include large-tablet {
    @include sh;
    font-weight: 300;
  }
}

@mixin paragraph {
  @include p-mobile;

  @include tablet {
    @include p;
  }
}

// mixin for different screen breakpoints
@mixin tablet {
  @media screen and (min-width: $tablet-breakpoint) {
    @content;
  }
}

@mixin large-tablet {
  @media screen and (min-width: $large-tablet-breakpoint) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: $desktop-breakpoint) {
    @content;
  }
}

@mixin square($length) {
  width: $length;
  height: $length;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin center {
  justify-content: center;
  align-items: center;
}

@mixin media-hover($color) {
  &:hover {
    transition: 0.3s;
    background-color: $color;
    color: $primary-white;
  }
}

@mixin button-style {
  position: relative;
  color: $primary-white;
  text-decoration: none;
  font-weight: 500;
  padding: 1rem;

  &:hover {
    color: $primary-white;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $primary-color;
    z-index: -1;
    border-radius: 1rem;
    transition: all 0.5s $base-ease;
  }

  &:hover:before {
    transform: scale(1.1);
  }
}
@use "../../Partials/mixins" as *;
@use "../../Partials/variables" as *;

.navbar {
  // general navbar styling
  min-height: 10vh;
  height: fit-content;
  width: 100%;
  background-color: white;
  transition: all 0.5s $base-ease;

  @include large-tablet {
    max-height: 8rem;
  }

  // push all buttons left, since the donate button's positioning is absolute
  &-nav {
    padding: 0 1rem;
    @include large-tablet {
      padding: initial;
      margin-right: 7rem;
    }
  }

  // styling for when the page is scrolled; shrinks the navbar overall and gives/changes drop shadow
  &--scroll {
    background-color: white;
    box-shadow: 0 6px 0 rgba($color: $paragraph-text, $alpha: 30%);

    @include large-tablet {
      max-height: 4.7rem;
    }
  }

  &--scroll &__logo {
    @include large-tablet {
      transform: scale(1.5);
    }
  }

  &__text {
    color: #000;
    font-size: 1.05rem;
  }

  &__logo {
    transition: all 0.7s $base-ease;
    transform: scale(1.5);
    margin-left: 1rem;

    @include large-tablet {
      transform: scale(2);
      margin-left: 3rem;
    }
  }
}

.nav {
  // styling for donate button
  &__donate {
    display: flex;
    @include center;
    width: fit-content;
    transform: translateX(1rem);

    @include large-tablet {
      position: absolute;
      right: 0;
      transform: none;
    }

    &-text {
      display: flex;
      @include center;
      font-size: 1.05rem;
      text-decoration: none;
      color: white;
      background-color: $primary-color;
      transition: all 0.7s $base-ease;
      padding: 1rem;
      border: 1px solid $primary-color;
      border-radius: $border-radius-sm;
      margin: 0 1rem 0 0;

      &:hover {
        background-color: white;
        color: black;
        border: 1px solid $primary-color;
      }

      // styling for when the page is scrolled
      &--scroll {
        @include large-tablet {
          background-color: white;
          color: black;

          &:hover {
            color: white;
            background-color: $primary-color;
          }
        }
      }
    }
  }

  // styling for each section of the nav
  &-item {
    margin: 0;
    width: fit-content;
  }

  &-link {
    border: 0;
    background-color: transparent;
  }
}

.dropdown-menu {
    padding: 0;
}

.dropdown-item {
    padding: 0.5rem 1rem;
    background-color: white;
    color: #000;
}

.dropdown-divider {
    margin: 0;
    border-top: solid #e9ecef;
}

.dropdown-menu .dropdown-item {
  background-color: white;
  color: #000;
}

// underline on hover for each section/subsection
.navbar__text:after,
.dropdown-menu .dropdown-item:after {
  content: "";
  // width is initially 0 so it is not displayed until it is hovered over
  width: 0%;
  // the height of the underline
  height: 2px;
  background-color: $primary-color;
  display: flex;
  // makes the underline start from the middle of the word, without it it will appear from right to left
  margin: auto;
  transition: all 0.5s $base-ease;
}

.navbar__text:hover::after,
.dropdown-menu .dropdown-item:hover::after {
  // underline is made visible on hover
  width: 100%;
}

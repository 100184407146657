@use '../../Partials/variables.scss' as *;

footer {
  &#visible-footer {
    background: linear-gradient(#444, #333);
  }
  * {
    color: white;
  }

  // selecting immediate child of the footer
  >div {
    text-align: center;
  }

  .footer-bottom {
    position: relative;
    padding: 1rem;
  }

  .copyright {
    font-size: 1rem;
    margin: 0;
  }

  .footer-text {
    font-size: 1rem;

    span {
      font-size: 1rem;

      a {
        text-decoration: none;

        &:hover {
          color: white;
        }
      }
    }
  }

  .footer-social {
    transition: 0.25s;
    &:hover {
      transform: translateY(-2px);
      color: $primary-red;
    }
  }

  ul {
    padding: 0;
    li {
      font-size: 0.8rem;
      display: inline-flex;
      margin: auto 0.75rem;
    }
  }
}
// scss file containing common animations used in the web page

@keyframes slide-in-left {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes slide-in-right {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@mixin fade-up($start, $duration) {
  @keyframes fade-up {
    0% {
      transform: translateY($start);
      opacity: 0;
    }
    100% {
      transform: translateY(0%);
      opacity: 1;
    }
  }
  animation: fade-up $duration ease-in-out;
}

@mixin fade-in($start, $direction, $duration, $transition: ease, $offsetX:0, $offsetY:0, ) {
  @if $direction == up {
    @keyframes fade-in {
      0% {
        transform: translateY($start);
        opacity: 0;
      }
      100% {
        transform: translateY(0%);
        opacity: 1;
      }
    }
  }
  animation: fade-in $duration ease-in-out;
}
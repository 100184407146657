@use '../../Partials/variables' as *;
@use '../../Partials/mixins' as *;

@include large-tablet {
    .home_field {
        margin-top: 5%;
        display: grid;
        grid-template-columns: 10% 25% 25% 5% 25% 10%;
    }

    .home_left, .home_right {
        display: block;
        transition: ease 0.5s;
    }

    .home_left:hover,
    .home_right:hover {
        transform: scale(1.05);
    }
}
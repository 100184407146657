@use "../../../Partials/mixins" as *;
@use "../../../Partials/variables" as *;

.goals-card {
    display: inline-block;
    text-align: left;
    padding-top: 0.5rem;
    @include square(6rem);

    @include tablet {
        @include square(6rem);
        padding-top: 1rem;
    }

    &__header {
        margin-top: 0rem;
        font-size: 0.9rem;
        padding-left: 1.25rem;
        margin: 0;

        @include tablet {
            font-size: 1.1rem;
        }
    }

    svg {
        font-size: 1rem;
    }

    &.left-goal {
        color: $primary-color;

        .goals-card__img {
            @include square(0.8rem);
            fill: $primary-color;
            margin-left: 1rem;
        }
    }

    &.right-goal {
        color: $primary-white;

        .goals-card__img {
            @include square(2rem);
            fill: $primary-white;
            margin-left: 2rem;
        }
    }

    &.full-image-goal {
        padding: 0;
        overflow: hidden;

        .goals-card__full-img {
            width: auto;
                height: auto;
                max-width: 100%;
                max-height: 100%;
                object-fit: cover;
        }
    }
}
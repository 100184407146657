@use '../../Partials/variables' as *;
@use '../../Partials/mixins' as *;

.form-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba($color: $paragraph-text, $alpha: 80%);
  z-index: 20;
  padding: 0 7vw;

  &--show {
    display: flex;
    @include center
  }

  &__main {
    @include flex-column;
    width: 100%;
    max-width: 15rem;
    height: 15rem;
    text-align: center;
    background-color: $primary-white;
    overflow: hidden;
    border-radius: $border-radius-default;
    @include tablet{
      max-width: 20rem;
      height: 20rem;
    }
  }

  &__top {
    @include flex-column;
    @include center;
    flex: 1.5;
    background-color: $primary-green;
    color: $primary-white;
    padding: 1.25rem;
    &--error {
      background-color: $primary-red;
    }
  }

  &__icon {
    font-size: 3rem;
  }

  &__body{
      @include flex-column;
      flex-wrap: wrap;
      text-align: center;
      flex: 1;
      justify-content: space-between;
      height: 100%;
      align-items: center;
      padding: 1rem;
  }

  &__title {
    @include subheader;
    margin-top: 1rem;
    margin-bottom: 0;
  }

  &__text {
    margin: 0 0 1rem 0;
    max-height: 7rem;
  }

  &__button {
    color: $primary-white;
    background-color: $primary-green;
    width: fit-content;
    padding: 0.5rem 2rem;
    border: 0;
    transition: all 0.5s $base-ease;

    &:hover {
      color: $primary-white;
      background-color: lighten($primary-green, 5%);
    }

    &--error {
      background-color: $primary-red;
      &:hover {
        color: $primary-white;
        background-color: lighten($primary-red, 5%);
      }
    }
  }
}

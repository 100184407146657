@use '../../Partials/variables' as *;
@use '../../Partials/mixins' as *;

.commitment {
  display: inline-block;
  height: 100%;
  width: 100%;
  padding: 3rem;
  box-shadow: 5px 3px 3px rgba(94, 94, 94, 0.2);
  background-color: rgb(250, 250, 250);
  border-radius: $border-radius-default;

  @media screen and (max-width: $large-tablet-breakpoint) {
    border-radius: 0;
  }

  .commitment-title {
    font-size: 2rem;
  }
}
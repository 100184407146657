@use '../../Partials/mixins' as *;
@use '../../Partials/variables' as *;
@use '../../Partials/animation' as *;

.info-brow {
    width: 50%;
    color: $primary-color;
}

.infocomp-section {
    margin: 0rem 2.5rem 2rem 2.5rem;
    opacity: 0;
    h1 {
        color: rgb(197, 56, 56);
    }

    &.fade-in {
        @include fade-up(50%, 0.75s);
        opacity: 1;
    }
}
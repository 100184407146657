@use '../../Partials/variables' as *;
@use '../../Partials/mixins' as *;

.class-card {
  margin: 0.5rem;
  padding: 0;
  border-radius: $border-radius-default;
  overflow: hidden;

  &__title {
    font-size: 1.7rem;
    line-height: 2.2rem;
    font-weight: 500;
  }

  &__top {
    min-height: 100%;
  }

  &__btm {
    font-size: 1.25rem;
  }

  .class-icons {
    color: $primary-color;
    font-size: 1.9rem;
  }

  .basic-list-style {
    list-style: none;
  }

  .class-list {
    display: inline-flex;
    margin-left: 7px;
  }
}
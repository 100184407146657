@use '../../Partials/variables' as *;
@use '../../Partials/mixins' as *;

.contact-cards {
  @include flex-column;
  justify-content: center;
  width: 100%;
  padding: 0 2rem;

  @include tablet {
    padding: 2rem;
    flex-direction: row;
  }

  &__card {
    @include flex-column;
    justify-content: flex-start;
    text-align: center;
    padding: 1rem 0;

    &--middle {
      border-top: 1px solid $placeholder-color;
      border-bottom: 1px solid $placeholder-color;
    }

    @include tablet {
      padding: 0 1rem;

      &--middle {
        border: none;
        border-left: 1px solid $placeholder-color;
        border-right: 1px solid $placeholder-color;
      }
    }

    @include large-tablet {
      padding: 0 3rem;
    }
  }

  &__subheader {
    font-size: 1.5rem;
    font-weight: 100;
    text-align: center;
  }

  &__icon {
    font-size: 2rem;
    margin: 0.7rem;
    margin-bottom: 1.5rem;
  }

  &__text {
    margin: 0;
    font-size: 1rem;
    text-decoration: none;
    color: $paragraph-text;
  }

  a:hover {
    color: $primary-color;
  }
}
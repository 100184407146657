@use '../../../Partials/variables' as *;
@use '../../../Partials/mixins' as *;
@use '../../../Partials/animation' as *;


.button-section {
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-top: 5rem;
    margin-bottom: 2rem;
}

@media only screen and (min-width: 1220px) {
    .main-body {
        margin: 0 5%;
    }

    .mission-and-philosophy-col {
        display: grid;
        grid-template-columns: 50% 50%;
    }
}

@media only screen and (min-width: 1400px) {
    .main-body {
        margin: 0 12.5%;
    }

}
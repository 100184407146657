@use '../../Partials/variables' as *;
@use '../../Partials/mixins' as *;

.mission {
  display: inline-block;
  background-color: $primary-color;
  color: $primary-white;
  border-radius: $border-radius-default;
  box-shadow: -3px 5px 5px rgba(44, 44, 44, 0.4);
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 3rem;

  .mission-title {
    font-size: 2rem;
  }

  @media screen and (max-width: $large-tablet-breakpoint) {
    border-radius: 0;
  }

}
@use '../../Partials/mixins' as *;
@use '../../Partials/variables' as *;

// the side bar styling is found in SideNav.scss

.our-projects {
  display: flex;
  position: relative;
  max-width: 100%;

  &__body {
    display: flex;
    flex: 1;
    width: 50%;
    margin: 0;
    padding: 0 2rem;
  }

  &__text {
    margin: 0;

    @include tablet {
      margin: 2rem;
    }
  }

  &__container {
    width: 100%;
  }

  .img-container {
    margin-top: 1rem;
    text-align: center;

    img {
      max-width: 25%;
      max-height: 25%;
    }
  }
}

#projects-root-page {
  @include flex-column;

  .preview-grid {
    display: grid;
    width: 100%;
    grid-template-columns: 50% 50%;
    gap: 3rem 1.5rem;
    position: relative;
    text-align: center;

    @include large-tablet {
      grid-template-columns: 33% 34% 33%;
      gap: 1.5rem 1.5rem;
    }

    @media screen and (max-width: 576px) {
      grid-template-columns: 100%;
      row-gap: 2.5rem;
    }
  }

  .preview-grid {
    @media screen and (min-width: 576px) {
      justify-content: center;
      margin-bottom: 3rem;
    }

    @include large-tablet {
      margin-bottom: 5rem;
    }

    div {
      position: relative;
      text-align: center;
      max-height: 15rem;

      .body {
        user-select: none;
        pointer-events: none;
        mask-image: linear-gradient(#000 2em, transparent);
        overflow: hidden;
      }

      // styling for the read more button
      .button-style {
        position: absolute;
        bottom: -2rem;
        left: 50%;
        transform: translateX(-50%);

        @media screen and (max-width: 576px) {
          bottom: 1rem;
        }
      }
    }
  }
  @media screen and (max-width: 3120px) {
    .project-entry-field {
      display: grid;
      grid-template-columns: auto auto auto auto auto auto;
    }
  
    .project-entry {
      display: flex;
      height: 100%;
      @include center;
      border: 0.5px solid $secondary-color;
      text-align: center;
      padding: 30px;
  
      &:hover {
        background-color: $primary-color;
        transition: ease 0.5s;
        color: white;
      }
    }
  }
  
  @media screen and (max-width: 1200px) {
    .project-entry-field {
      display: grid;
      grid-template-columns: auto auto auto auto;
    }
  
    .project-entry {
      border: 0.5px solid $secondary-color;
      text-align: center;
      padding: 30px;
  
      &:hover {
        background-color: $primary-color;
        transition: ease 0.5s;
        color: white;
      }
    }
  
    .project-entry:hover .external-link {
      transition: ease 0.5s;
      color: white;
    }
  }
  
  @media screen and (max-width: 768px) {
    .project-entry-field {
      display: grid;
      grid-template-columns: auto auto;
    }
  
    .project-entry {
      border: 0.5px solid $secondary-color;
      text-align: center;
      padding: 30px;
  
      &:hover {
        background-color: $primary-color;
        transition: ease 0.5s;
        color: white;
      }
    }
  
    .project-entry:hover .external-link {
      transition: ease 0.5s;
      color: white;
    }
  }
}


.BlogCarouselSlide__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    box-sizing: border-box;
    width: 100%; // Ensure it takes the full width
    height: 100%; // Ensure it takes the full height
}

.BlogCarouselSlide__image {
    max-width: 100%;
    height: auto;
}

.BlogCarouselSlide__title {
    font-size: 1.5rem;
    margin: 10px 0;
}

.BlogCarouselSlide__description {
    font-size: 1rem;
    margin: 10px 0;
}

.BlogCarouselSlide__link {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
}
@use '../../Partials/variables' as *;
@use '../../Partials/mixins' as *;

.partner-carousel {
  display: flex;
  position: relative;
  margin: 2rem 0;

  &__btn {
    position: absolute;
    color: $primary-color;
    background-color: transparent;
    border: 0;
    z-index: 1;
    top: 50%;
    transition: all 0.5s $base-ease;

    &--right {
      right: 0.5rem;

      &:hover {
        transform: translateX(-20%);
      }
    }

    &--left {
      left: 0.5rem;

      &:hover {
        transform: translate(20%);
      }
    }
  }

  &__icon {
    @include tablet {
      transform: scale(2);
    }
  }

  // style for each card
  &__item {
    display: flex;
    @include center;
    min-height: 10rem;
    max-height: 10rem;
    user-select: none;
  }
}
@use '../../Partials/variables' as *;
@use '../../Partials/mixins' as *;
@import "react-alice-carousel/lib/scss/alice-carousel.scss";

.home-carousel {
    position: relative;

    li > div {
        background-attachment: fixed;
        background-position: 50% 25%;
        background-size: cover;
    }

    .home-carousel__body {
        @include flex-column;
        @include center;
        color: $primary-white;
        text-shadow: 2px 2px 4px #474747;
        padding: 0 12.5%;
        width: 100%;
        min-height: 60vh;
        height: fit-content;
        backdrop-filter: brightness(70%);

        .header {
            font-size: 8vw;
        }

        .subheader {
            font-size: 5vw;
        }

        .header,
        .subheader {
            padding: 0 0.5em;
            text-align: center;
            user-select: none;
            line-height: normal;
        }

        @include tablet {
            .header {
                font-size: 4rem;
            }

            .subheader {
                font-size: 3rem;
            }
        }
    }

    &__btn {
        position: absolute;
        display: flex;
        @include center;
        color: $primary-white;
        background-color: transparent;
        border: 0;
        padding: 0;
        z-index: 1;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;

        &--right {
            right: 1rem;

            @include tablet {
                right: 3rem;
                transform: translate(-50%, -50%);
            }
        }

        &--left {
            left: 1rem;

            @include tablet {
                left: 3rem;
            }
        }

        i {
            font-size: 1.5rem;

            @include large-tablet {
                font-size: 2em;
            }
        }
    }

    .our-goal {
        display: block;
        position: absolute;
        bottom: 0;
        left: 1.5rem;
        justify-content: right;
        justify-items: right;
        text-align: left;

        @include tablet {
            right: 4rem;
        }
    }
}
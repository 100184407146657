@use "../../Partials/mixins" as *;
@use "../../Partials/variables" as *;

.side-nav {
  @include flex-column;
  background-color: $secondary-white;
  transition: all 0.7s ease;
  position: absolute;
  width: 0;
  height: 36rem;
  max-height: calc(100% - 4rem);
  z-index: 1;
  
  @include tablet {
    position: relative;
    width: fit-content;
    height: fit-content;
    max-width: 40%;
  }
  
  &__content {
    @include flex-column;
    // to prevent horizontal slider from appearing unecessarily
    overflow-x: hidden;
  }

  &--expand {
    width: calc(100% - 14vw);
    @include desktop {
      width: fit-content;
    }
  }

  &__button {
    position: absolute;
    right: -20px;
    border: 0;
    background-color: transparent;
    color: $primary-color;
    transition: all 0.5s $base-ease;

    // hide button until screen width < 768px
    @include tablet {
      display: none;
    }

    &:hover {
      transform: translateX(7%);
    }

    &--expand {
      &:hover {
        transform: translateX(-7%);
      }
    }
  }

  &__link {
    font-size: 1rem;
    font-weight: bold;
    text-decoration: none;
    color: $paragraph-text;
    padding: 1rem;

    &:hover {
      background-color: darken($color: $card-color, $amount: 10);
      color: $primary-color;
      transition: 0.25s;
    }

    &.active {
      background-color: $primary-color;
      color: $primary-white;
    }
  }
}
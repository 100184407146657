.blogCarousel {
    position: relative;

    .item {
        display: flex;
        justify-content: center;
        align-items: center;
        height: auto;
        padding: 10px;
    }

    .blogCarouselSlide {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        width: 100%;
        max-width: 90vw; // Max width relative to viewport
        padding: 20px;
        box-sizing: border-box;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        text-align: center;
        overflow: hidden;

        img {
            height: 200px; // Fixed height based on img2 size
            width: 100%; // Make sure the width is 100% of the container
            object-fit: cover; // Maintain aspect ratio while covering the container
            border-radius: 8px 8px 0 0; // Rounded corners at the top
        }

        .blogCarouselSlide__title {
            font-size: 1.5rem;
            margin: 10px 0;
            font-weight: bold;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .blogCarouselSlide__date {
            font-size: 1rem;
            color: #888;
            margin-bottom: 10px;
        }

        .blogCarouselSlide__description {
            font-size: 1rem;
            line-height: 1.5;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3; // Limit the text to 3 lines
            -webkit-box-orient: vertical;
        }

        .blogCarouselSlide__link {
            margin-top: auto;
            font-size: 1rem;
            color: #007bff;
            text-decoration: underline;
        }
    }

    .blogCarousel__btn {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-color: transparent;
        border: none;
        font-size: 2rem;
        cursor: pointer;

        &--left {
            left: 10px;
        }

        &--right {
            right: 10px;
        }

        & .blogCarousel__icon {
            color: #333;
        }
    }

    @media (max-width: 768px) {
        .blogCarouselSlide {
            padding: 15px;

            img {
                height: 150px; // Adjust height for smaller screens
            }

            .blogCarouselSlide__title {
                font-size: 1.2rem;
            }

            .blogCarouselSlide__description {
                font-size: 0.9rem;
            }

            .blogCarouselSlide__date {
                font-size: 0.8rem;
            }
        }

        .blogCarousel__btn {
            font-size: 1.5rem;
        }
    }

    @media (max-width: 480px) {
        .blogCarouselSlide {
            padding: 10px;

            img {
                height: 120px; // Further adjust for very small screens
            }

            .blogCarouselSlide__title {
                font-size: 1rem;
            }

            .blogCarouselSlide__description {
                font-size: 0.8rem;
            }

            .blogCarouselSlide__date {
                font-size: 0.7rem;
            }
        }

        .blogCarousel__btn {
            font-size: 1.2rem;
        }
    }
}
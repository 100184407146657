@use '../../Partials/mixins' as *;
@use '../../Partials/variables' as *;

.blog {
  .hero-blog {
    height: 70vh;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .blog-card {
    @include flex-column;
    border: 2px solid $secondary-color;
    transition: 0.5s $base-ease;
    padding: 1rem;

    img {
      object-fit: cover;
      height: 50%;
      width: 100%;
      padding-bottom: 1rem;
    }

    &__btn {
      width: 100%;
      text-decoration: none;
      font-weight: bold;
      text-align: center;
      font-size: 1rem;
      border: 2px solid $primary-red;
      padding: 0.5rem 0;
      background-color: transparent;
      color: $primary-red;
      border-radius: 0.2rem;
      transition: 0.5s $base-ease;
      align-self: end;

      @include tablet {
        padding: 0.75rem;
      }

      &:hover {
        background-color: $primary-red;
        color: white;
      }
    }

    &:hover {
      transform: scale(1.05);
    }
  }

  .reports {
    @include center;
    iframe {
      width: 100%;
      height: clamp(250px, 75vw, 90vh);
    }
  }
}
@use '../../../Partials/variables' as *;
@use '../../../Partials/mixins' as *;

.president {
  display: flex;
  @include center;
  margin-bottom: 2rem;
  text-align: center;
  
  &__name {
    font-size: 2rem;
    margin-top: 1rem;
  }

  &__content {
    margin-left: 1.5rem;
  }

  &__title {
    font-size: 1.2rem;
    margin-top: 1rem;
    color: $label-color;
  }

  &__info {
    font-size: 1.1rem;
    margin-top: 1rem;
    text-align: initial;
  }

  &__img {
    max-width: 320px;
    object-fit: cover;
    overflow: hidden;
  }
}
@use '../../Partials/mixins' as *;
@use '../../Partials/variables' as *;

.post-card {
    @include flex-column;
    position: relative;
    justify-content: center;
    height: 20rem;
    margin: 0.1rem;
    padding: 3rem;
    color: $primary-white;
    text-align: center;
    overflow: hidden;

    img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        user-select: none;
        pointer-events: none;
        // darken background so text is more visible
        filter: brightness(75%);
        transition: 0.5s $base-ease;

        @media screen and (max-width: 992px) {
            filter: brightness(40%);
        }
    }

    &__title {
        @include subheader;
        z-index: 1;
    }

    &__body {
        z-index: 1;
        display: none;
        overflow: auto;
        height: 100%;
    }

    &__link {
        text-decoration: none;
    }

    @include large-tablet {
        justify-content: center;
        width: 100%;
        height: 40rem;
        padding: 1.5rem;
        background-color: #0000004d;
        transition: all 0.5s $base-ease;

        &__title {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 1.5rem;
            padding: 1rem;
            font-size: 2 rem !important;
            color: white;

            @include desktop {
                font-size: 2 rem !important;
            }
        }

        &__body {
            display: none;
            color: white;
            text-decoration: none;
        }

        &:hover {
            transform: scale(1.1);
            z-index: 2;

            img {
                filter: brightness(25%);
            }


            .post-card__title {
                position: relative;
                padding: 0;
            }

            .post-card__body {
                position: relative;
                display: block;
                height: 10%;
                overflow-y: clip;
                text-decoration: none;
            }
        }
    }
}
@use '../../../Partials/variables' as *;

.leadership {
    background-color: rgb(252, 252, 252);
    padding-bottom: 50px;

    .hero-banner {
        margin-bottom: 30px;
    }

    .main-ld-body {
        padding: 20px 0;
    }

    @media only screen and (min-width: 1260px) {
        .main-ld-body {
            margin-left: 15%;
            margin-right: 15%;
        }
    }

    @media only screen and (max-width: 1260px) {
        .main-ld-body {
            margin-left: 10%;
            margin-right: 10%;
        }
    }

    .board-member {
        background-color: white;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        margin-bottom: 20px;
        padding: 20px;
        text-align: center;
        transition: transform 0.3s ease;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;

        &:hover {
            transform: translateY(-5px);
        }

        img {
            border-radius: 50%;
            width: 100%;
            max-width: 150px;
            height: auto;
            object-fit: cover;
            margin-bottom: 15px;
            display: block;
        }

        .member-title {
            font-size: 1.2rem;
            font-weight: 600;
            margin-bottom: 10px;
            color: #333;
        }

        .member-name {
            font-size: 1rem;
            margin-bottom: 10px;
            color: #555;
        }

        .member-info {
            font-size: 0.9rem;
            color: #777;
            line-height: 1.6;
            text-align: center;
            max-height: 6em;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .president {
        .board-member {
            background-color: #f8f9fa;
            border-left: 4px solid #007bff;
        }
    }

    .row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around; // This will center the items and give them more space
    }

    @media only screen and (max-width: 768px) {
        .board-member {
            margin-bottom: 15px;
            padding: 15px;

            img {
                max-width: 120px;
                height: auto;
            }
        }
    }

    @media only screen and (max-width: 480px) {
        .board-member {
            padding: 10px;

            img {
                max-width: 100px;
                height: auto;
            }

            .member-title {
                font-size: 1rem;
            }

            .member-name {
                font-size: 0.9rem;
            }

            .member-info {
                font-size: 0.8rem;
            }
        }
    }
}
@use "../../Partials/mixins" as *;
@use "../../App.scss" as *;

.resource-card {
  .card-title {
    font-size: 1.25rem;

    @include tablet {
      font-size: 1.5rem;
    }
  }

  .info {
    font-size: 1rem;
    margin-top: 0.5rem;

    @include tablet {
      font-size: 1rem;
    }

    a {
      @extend .external-link;
    }
  }
}

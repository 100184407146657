@use '../../Partials/mixins' as *;

.partner-card {
  display: flex;
  @include center;

  &__link {
    transition: 0.25s;
    display: flex;

    &:hover {
      transition: 0.25s;
      transform: scale(1.05);
    }
  }

  // scale down logo on smaller screens
  &__logo {
    transform: scale(0.5);

    @include large-tablet {
      transform: scale(1);
    }
  }
}
@use '../../Partials/variables' as *;
@use '../../Partials/mixins' as *;
@use '../../Partials/animation' as *;

.hero-banner {
  padding: 2rem 7vw 1rem 7vw;
  background: linear-gradient(#bd4f4f, 65%, $primary-red);
  // background-color: $primary-color;
  color: $primary-white;
  overflow: hidden;
  border-top: 3px solid rgba($color: $primary-color, $alpha: 0.5);

  // Have all parts of the elements of the banner fade up when it is loaded in
  * {
    @include fade-up(300%, 0.75s);
  }

  &__eyebrow {
    border-bottom: 2px solid $primary-white;
  }

  &__title {
    @include hero-header;
    margin-bottom: 1.5rem;
  }

  @include large-tablet {
    padding: 4rem 7vw 2rem 7vw;
  }
}
@use '../../Partials/variables' as *;
@use '../../Partials/mixins' as *;

.slide-card-section {
  display: flex;
  justify-content: center;
  width: 100%;

  &__container {
    @include flex-column;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @include tablet {
      flex-direction: row;
      max-width: 90%;
    }

    @include large-tablet {
      max-width: 80rem;
    }
  }
}
@use '../../../Partials/variables' as *;
@use '../../../Partials/mixins' as *;

.fact-body {
  margin-left: 5%;
  margin-right: 5%;

  .fact-number {
    font-weight: 600;
    font-size: 4rem;
    color: $paragraph-text;
  }

  .fact-title {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 300;
    color: $primary-color;
  }
}
@use '../../../Partials/variables' as *;
@use '../../../Partials/mixins' as *;
@use '../../../Partials/animation' as *;

.our-work {
    .hero-banner-container {
        margin-bottom: 2rem;
    }

    .commit-section__text-container {
        padding: 2rem;
        background: #f9f9f9;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        animation: fadeIn 1.5s ease-in-out;
    }

    .commitment-title {
        font-size: 2rem;
        color: $primary-color;
        margin-bottom: 1rem;
        position: relative;

        &:after {
            content: "";
            display: block;
            width: 50px;
            height: 4px;
            background: $secondary-color;
            margin-top: 0.5rem;
            animation: slideIn 1s ease-in-out;
        }
    }

    .text-content {
        font-size: 1rem;
        color: #333;
        margin-bottom: 1.5rem;
        line-height: 1.6;
        text-align: justify;
        animation: fadeInUp 1.5s ease-in-out;
    }

    .program-list,
    .program-goals-list {
        margin-bottom: 2rem;
        padding-left: 1.5rem;

        li {
            margin-bottom: 1rem;

            &:before {
                content: "•";
                color: $primary-color;
                display: inline-block;
                width: 1rem;
                margin-left: -1rem;
                animation: bounceIn 1s ease-in-out;
            }

            .nested-list {
                padding-left: 1rem;

                li {
                    &:before {
                        content: none;
                    }
                }
            }
        }
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideIn {
    from {
        width: 0;
    }

    to {
        width: 50px;
    }
}

@keyframes bounceIn {
    from {
        transform: scale(0);
        opacity: 0;
    }

    to {
        transform: scale(1);
        opacity: 1;
    }
}

@media only screen and (min-width: 768px) {
    .commit-section__text-container {
        padding: 3rem;
    }

    .commitment-title {
        font-size: 2.5rem;
    }

    .text-content {
        font-size: 1.1rem;
    }
}

@media only screen and (min-width: 1220px) {
    .commit-section__text-container {
        margin: 0 10%;
    }

    .commitment-title {
        font-size: 3rem;
    }

    .text-content {
        font-size: 1.2rem;
    }
}
@use '../../../Partials/mixins' as *;

// main components on this page are found in PartnerCard.scss

.partners {
  margin-top: 1rem;
  @include tablet {
    margin-top: 2rem;
  }
  
  &__list {
    list-style-type: none;
    padding: 0;
  }

  &__item {
    display: flex;
    @include center;
    padding: 1rem;
    @include tablet {
      padding: 2rem;
    }
    @include large-tablet {
      padding: 3rem;
    }
  }
}

@use "../../Partials/variables" as *;
@use "../../Partials/mixins" as *;

.gallery-carousel {
  display: flex;
  position: relative;
  margin: 2rem 0;
  background-color: black;
  width: 100%;
  overflow: hidden;

  &__item {
    display: flex;
    @include center;
  }

  &__img {
    object-fit: contain;
    width: 100%;

    @include tablet {
      max-height: 600px;
    }
  }

  &__icon {
    @include tablet {
      transform: scale(1.5);
    }
  }

  &__btn {
    position: absolute;
    display: flex;
    @include center;
    color: $primary-white;
    background-color: transparent;
    border: 0;
    transition: all 0.5s $base-ease;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);

    &--right {
      right: 1rem;
    }

    &--left {
      left: 1rem;
    }
  }

  & .alice-carousel {
    li {
      vertical-align: middle;
    }
  }
}

@use '../../Partials/variables' as *;
@use '../../Partials/mixins' as *;

.payment-form {
  #donationFormTitle {
    background-color: $primary-color;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: $border-radius-default;
    color: $primary-white;
  }

  .donate {
    &__middle {
      @include tablet {
        margin-left: .5rem;
        margin-right: .5rem;
      }
    }
  }

  input {
    &:focus {
      box-shadow: none;
    }
  }
  
  #cardOwner, #cvv, #cardOwner {
    border: none;
    background-color: transparent;
    border-bottom: 1px solid gray;
    border-radius: 0;
  }
}
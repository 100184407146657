// Global css styles 
// Throughout the app these styling rules will be applied

@use './Partials/variables' as *;
@use './Partials/mixins' as *;

@import url("https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&display=swap");

html {
  scroll-behavior: smooth !important;
}

.App {
  @include flex-column;
  max-width: 100vw;
  min-height: 100vh;
  font-family: "Hind", sans-serif;
  box-sizing: border-box;
  color: $paragraph-text;
  @include paragraph;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  color: inherit;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
}

button {
  background: transparent;
  border: none;
}

// default padding for pages
.main {
  padding: 2rem 7vw;
}

.primary-color {
  color: $primary-color;
}

.secondary-color {
  color: $secondary-color;
}

.primary-white {
  color: $primary-white;
}

.backgound-primary {
  background-color: $primary-color;
}

.backgound-secondary {
  background-color: $secondary-color;
}

.header {
  @include header;
}

.subheader {
  @include subheader;
}

.label {
  @include label;
}

.short-underline {
  position: relative;
  width: fit-content;

  &:before {
    content: "";
    position: absolute;
    width: 50%;
    height: 1px;
    bottom: -25%;
    left: 25%;
    border-bottom: 2px solid $primary-color;
  }
}

.short-underline-left {
  position: relative;
  width: fit-content;

  &:after {
    content: "";
    position: absolute;
    width: 70%;
    bottom: -25%;
    left: 0;
    border-bottom: 2px solid $primary-color;
  }
}

.divider {
  &:after {
    content: "";
    width: 85%;
    left: 25%;
    border-bottom: 2px solid rgba($color: $label-color, $alpha: 0.5);
    padding-top: 1.5rem;
    margin-bottom: 1.5rem;
    margin-left: auto;
    margin-right: auto;
  }
}

.card-style {
  background-color: $primary-white;
  border: 1px solid $secondary-white;
  border-radius: 1rem;
  transition: all 0.7s $base-ease;
  width: 100%;
  overflow: hidden;
  // @include tablet {
  //   &:hover {
  //     transform: scale(1.1);
  //   }
  // }
}

// for hover effect
.button-style {
  margin-top: 1.5rem;
  position: relative;
  color: $primary-white;
  text-decoration: none;
  font-weight: 500;
  padding: 1rem;
  z-index: 1;

  &:hover {
    color: $primary-white;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $primary-color;
    z-index: -1;
    border-radius: $border-radius-sm;
    transition: all 0.5s $base-ease;
  }

  &:hover:before {
    transform: scale(1.1);
  }
}

// just a line under page title
.title-line {
  margin-left: 45%;
  margin-right: 45%;
  border: 1px solid $primary-color;
  margin-bottom: 30px;
  margin-top: 30px;
}

// title for pages
.page-title {
  text-align: center;
  color: $primary-color;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 2rem;
}

// to add shadow to components
.default-shadow {
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.2), 0 6px 15px 0 rgba(0, 0, 0, 0.19);
}

// to add shadow when hover over components
.full-shadow:hover {
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

// specific style for external redirect links
.external-link {
  color: $primary-color;
  text-decoration: none;
  position: relative;

  &:hover {
    color: $primary-red;
    transition: 0.25s;
  }
}
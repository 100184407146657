@use '../../../Partials/mixins' as *;
@use '../../../Partials/variables' as *;
@use '../../../Partials/animation' as *;

.side-link {
  @include flex-column;
  @include center;
  @include square(3rem);
  color: $paragraph-text;
  border: 1px solid rgba(37, 37, 37, 0.493);
  border-radius: 50%;
  margin-top: 2px;
  margin-bottom: 2px;
  background-color: rgba(255, 255, 255, 0.493);
  transition: 0.3s;

  &--Facebook {
    @include media-hover(#3b5998);
  }

  &--Twitter {
    @include media-hover(#00aced);
  }

  &--Instagram:hover {
    background-image: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5849 45%, #d6249e 60%, #2859eb 90%);
    color: rgba(255, 255, 255, 0.899);
  }

  &--Linkedin {
    @include media-hover(#007bb6);
  }

  &--Youtube {
    @include media-hover(#e62117);
  }

  &__icon {
    font-size: 1.5rem;

    &:before {
      display: inline-block;
      text-decoration: none;
    }
  }
}
@use '../../../Partials/variables' as *;
@use '../../../Partials/mixins' as *;
@use '../../../Partials/animation' as *;



    hr {
        z-index: 1;
        display: block;
        position: absolute;
        margin-top: 1rem;
        width: 80%;
        float: bottom;
        border: none;
        border-top: 3px solid white;
    }

.main-body {
    
}

.button-section {
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-top: 5rem;
    margin-bottom: 2rem;
}

@media only screen and (min-width: 1220px) {
    .main-body {
        margin: 0 5%;

        h2 {
            color: rgb(197, 56, 56);
        }

        h3 {
            color: rgb(197, 56, 56);
        }

        p {
            text-align: justify;
        }

        img {
            width: 100%;
            height: 40rem;
            object-fit: cover;
            object-position: center;
            overflow: hidden;
            margin-bottom: 2rem;
        }
    }

}

@media only screen and (min-width: 1400px) {
    .main-body {
        margin: 0 12.5%;

        h3 {
            color: rgb(197, 56, 56);
        }
    }
}
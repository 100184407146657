@use '../../Partials/variables' as *;
@use '../../Partials/mixins' as *;

.error-msg {
  display: flex;
  @include center;
  width: fit-content;
  background-color: $primary-red;
  margin-top: 1px;
  font-size: 0.75rem;
  color: $primary-white;
  padding: 0 0.5rem;
  border-radius: $border-radius-sm;

  @include tablet {
    font-size: 1rem;
  }
}
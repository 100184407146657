@use '../../Partials/variables' as *;
@use '../../Partials/mixins' as *;

.form {
  width: 100%;

  &__field {
    position: relative;
    margin-top: 0.5rem;
    min-height: 4rem;

    &--big {
      min-height: 10rem;
    }
  }

  &__checkbox {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    margin: 0.5rem 0;
    align-items: center;

    &-label {
      margin-left: 0.5rem;
    }
  }

  &__input,
  &__select {
    position: relative;
    transition: all 0.5s $base-ease;

    &--invalid {
      border: 1px solid $primary-red;

      &:focus {
        border-color: $primary-red;
        outline: 0;
      }
    }

    &:focus {
      border-color: rgb(221, 27, 27);
      box-shadow: none;
    }

    &--valid {
      border: 1px solid $primary-green;

      &:focus {
        border-color: $primary-green;
        outline: 0;
      }
    }
  }

  // label styles
  &__placeholder {
    position: absolute;
    color: $placeholder-color;
    line-height: 1rem !important;
    font-size: 1rem;
    top: 12px;
    margin-left: 1rem;
    pointer-events: none;
    transform-origin: 0 50%;
    transition: all 0.2s $base-ease;
    z-index: 1;

    &:after {
      content: " ";
      background-color: $primary-white;
      width: 100%;
      height: 4px;
      position: absolute;
      bottom: 7px;
      left: 0;
      z-index: -1;
    }
  }

  // input,select: change label color based on valid or invalid
  &__input--valid~&__placeholder,
  &__select--valid~&__placeholder {
    color: $primary-green;
  }

  &__input--invalid~&__placeholder,
  &__select--invalid~&__placeholder {
    color: $primary-red;
  }

  // change label color and position if focused or has input
  &__input:focus~&__placeholder,
  &__input:not(:placeholder-shown)~&__placeholder,
  &__select:focus~&__placeholder,
  &__select--selected~&__placeholder {
    transform: translateY(-19px) translateX(0.25rem) scale(0.8);
    padding-left: 0.25rem;
  }

  &__input:focus~&__placeholder,
  &__select:focus~&__placeholder {
    color: rgb(221, 27, 27);
  }

  &__input--valid:focus~&__placeholder,
  &__input--valid:not(:placeholder-shown)~&__placeholder,
  &__select--valid:focus~&__placeholder,
  &__select--valid~&__placeholder {
    color: $primary-green;
  }

  &__input--invalid:focus~&__placeholder,
  &__input--invalid:not(:placeholder-shown)~&__placeholder,
  &__select--invalid:focus~&__placeholder,
  &__select--invalid:focus~&__placeholder {
    color: $primary-red;
  }

  &__submit {
    display: flex;
    @include center;
    color: $primary-white;
    background-color: $primary-color;
    margin: 0.5rem 0;
    width: 50%;
    border: 0;
    border-radius: $border-radius-default;
    transition: all 0.7s $base-ease;
    padding: 0.5rem 2rem;

    &--left {
      margin-right: auto;
    }

    &--right {
      margin-left: auto;
    }

    &--middle {
      margin-left: auto;
      margin-right: auto;
    }

    &:hover {
      background-color: lighten($primary-color, 5%);
    }

    @include tablet {
      width: fit-content;
    }
  }

  &__file {
    @include flex-column;
  }
}
@use '../../Partials/variables' as *;
@use '../../Partials/mixins' as *;
.contact {
    &__container {
        background-color: $primary-white;
        margin: 0;
    }
    &__box {
        padding: 0;
        border: 0;
        overflow: hidden;
        padding-bottom: 100%;
        position: relative;
        height: 0;
    }
    &__form-card {
        @include large-tablet {
            padding: 0 3rem;
            padding-left: 5rem;
        }
    }
    .contact_form {
        padding: 3rem;
        box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
    }
    .main_contact_box {
        padding: 2rem;
    }
    // media queries for smaller screens
    @media only screen and (min-width: 1060px) {
        .field {
            display: grid;
            grid-template-columns: 50% 50%;
            .left_field {
                padding: 2rem;
            }
            .right_field {
                padding: 4rem 2rem;
                iframe {
                    height: 100%;
                    width: 100%;
                    filter: saturate(105%);
                }
            }
        }
    }
    @media only screen and (min-width: 960px) and (max-width: 1060px) {
        .field {
            display: grid;
            grid-template-columns: 50% 50%;
            .right_field iframe {
                padding: 2rem;
                height: 100%;
                width: 100%;
            }
        }
    }
    @media only screen and (max-width: 960px) {
        .field .right_field {
            padding: 2rem;
            height: 30rem;
            iframe {
                height: 100%;
                width: 100%;
            }
        }
    }
}
@use "../../Partials/mixins" as *;
@use "../../Partials/variables" as *;

.booklet-container {
  @include flex-column;

  .previews {
    display: flex;
    flex-direction: row;
    text-align: center;

    .preview-card {
      padding: 0.5rem 0;
      text-align: center;
      margin: 0 4.167%;
      user-select: none;

      img {
        min-width: 128px;
        width: 50%;
        margin-bottom: 1rem;
      }

      &:hover {
        cursor: pointer;
      }

      p {
        margin: 0
      }

      // specific style for the card that matches the booklet that is on screen
      &__active {
        @extend .preview-card;
        background-color: $secondary-white;

        img {
          min-width: 128px;
          width: 50%;
          height: auto;
          margin-bottom: 1rem;
        }
      }
    }
    &__subheader {
      font-size: 0.9rem;
    }
  }

  // video embed
  .video-wrapper {
    position: relative;
    padding-bottom: 28.125%;
    iframe {
      margin: 1em 0;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 50%;
      height: 100%;
    }
  }
}
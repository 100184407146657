// standard variables values used throughout the website

// color palette
$primary-color: #b02626;
$secondary-color: #eeeeee;
// 029fda
// 04a5e2
$primary-blue: #04a5e2;
$primary-green: #19be87;
$primary-red: #bd0000;

$footer-background: #303945;
$footer-text: #868d8b;

$darkBlue: #1e5c9f;
$lightBlue: #6297d0;

$label-color: #8b939c;

$paragraph-text: #323232;

$primary-white: #ffffff;
$secondary-white: #f5f5f5;

$placeholder-color: #afafaf;

$card-color: #fafafa;

// default border radius
$border-radius-default: 1rem;
$border-radius-sm: 0.5rem;

// breakpoints
$desktop-breakpoint: 1280px;
$large-tablet-breakpoint: 992px;
$tablet-breakpoint: 768px;

// transition
$base-ease: cubic-bezier(0.165, 0.84, 0.44, 1);
@use '../../Partials/variables' as *;
@use '../../Partials/mixins' as *;

$base-duration: 500ms;
// ================================================
// Transforms used for parallax effect
// Try changing the --d values within each rule-set
// and hover over the slide
// ================================================

.slide {
  --x: 0;
  --y: 0;
  --d: 50;

  &:hover .slide__image-wrapper {
    transform: scale(1.025)
      translate(
        calc(var(--x) / var(--d) * 1px),
        calc(var(--y) / var(--d) * 1px)
      );
  }
}

.slide__image {
  --d: 20;

  transform: translate(
    calc(var(--x) / var(--d) * 1px),
    calc(var(--y) / var(--d) * 1px)
  );
}

.slide__content {
  --d: 60;

  transform: translate(
    calc(var(--x) / var(--d) * -1px),
    calc(var(--y) / var(--d) * -1px)
  );
}

// ========================
// Button
// ========================

.slide__btn {
  position: relative;
  font-size: 0.75rem;
  color: $primary-white;
  background-color: $primary-color;
  border: none;
  border-radius: $border-radius-default;
  padding: 0.25rem 1rem;
  margin-top: 0.5rem;
  z-index: 1;
  text-decoration: none;
  transition: all 0.5s $base-ease;

  &:hover {
    color: $primary-white;
    background-color: lighten($primary-color, 5%);
  }
  &:active {
    transform: translateY(1px);
  }
}

// ========================
// Slide
// ========================

.slide {
  align-items: center;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  text-align: left;
  width: 100%;
  height: 30rem;
  margin: 1rem;
  z-index: 1;
  transition: all 0.7s $base-ease;

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}

// ========================
// Slide image wrapper
// ========================

.slide__image-wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0%;
  left: 0%;
  border-radius: $border-radius-sm;
  overflow: hidden;
  transition: all 0.7s $base-ease;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      to bottom,
      rgba($color: #000000, $alpha: 0) 0%,
      rgba($color: $paragraph-text, $alpha: 1) 100%
    );
    z-index: 1;
  }
}

// ========================
// Slide image
// ========================

.slide__image {
  --d: 20;

  height: 110%;
  left: -5%;
  object-fit: cover;
  pointer-events: none;
  position: absolute;
  top: -5%;
  transition: all 0.7s $base-ease;
  user-select: none;
  width: 110%;
  object-fit: cover;
}

// ========================
// Slide headlines
// ========================

.slide__headline {
  position: relative;
  margin: 0;
  @include subheader;
  @include tablet {
    @include paragraph;
  }
  font-weight: 500;
  @include large-tablet {
    @include subheader;
  }
}

// ========================
// Slide content
// ========================

.slide__content {
  --d: 60;

  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  padding: 1rem;
  z-index: 1;
  transition: all 0.7s $base-ease;
}

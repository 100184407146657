@use "../../Partials/variables" as *;
@use "../../Partials/mixins" as *;

.subscribe-block {
  text-align: center;
  display: block;
  padding: 3rem;
  background-color: $primary-color;

  label {
    display: block;
    color: white;
    font-size: 1.5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .subscribe-email {
    display: inline-block;
    padding: 1rem;
    width: 50%;
    border: none;
    border-radius: $border-radius-sm;
    text-align: center;
    height: 3rem;
  }

  .subscribe-submit {
    display: inline-block;
    margin-left: 0.25rem;
    margin-right: auto;
    background-color: rgb(104, 8, 8);
    border: none;
    color: white;
    font-size: 1rem;
    border-radius: $border-radius-sm;
    margin-top: 1.1rem;
    height: 2.9rem;
  }

  .subscribe-submit:hover {
    background-color: rgb(124, 13, 13);
    color: white;
  }

  .form_field {
    text-align: center;
    align-self: center;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
  }
}
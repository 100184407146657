@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

// Define theme colors
$primary-color: #bd4241;
$secondary-color: #a63a3b;
$background-light: #f9f9f9;

.our-work {

    // Ensure the hero banner sits at the top
    .hero-banner-container {
        margin-bottom: 2rem;

        // Optionally, if you wish to override the HeroBanner background:
        .hero-banner {
            background: linear-gradient(135deg, $primary-color, $secondary-color);
            color: #fff;
        }
    }

    // The main content “card” that holds text and sections
    .commit-section__text-container {
        padding: 2rem;
        background: $background-light;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        animation: fadeIn 1.5s ease-in-out;
        margin-top: 1rem; // Add a little spacing so it doesn’t overlap the hero banner
    }

    .commitment-title {
        font-size: 2rem;
        color: $primary-color;
        margin-bottom: 1rem;
        position: relative;
        text-align: left;

        &:after {
            content: "";
            display: block;
            width: 50px;
            height: 4px;
            background: $secondary-color;
            margin-top: 0.5rem;
            animation: slideIn 1s ease-in-out;
        }
    }

    .text-content {
        font-size: 1rem;
        color: #333;
        margin-bottom: 1.5rem;
        line-height: 1.6;
        text-align: justify;
        animation: fadeInUp 1.5s ease-in-out;
    }

    // Process Section styling
    .process-section {
        margin-top: 2rem;

        .process-cards {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
            gap: 1.5rem;
            margin-bottom: 2rem;
        }

        .process-card {
            background: #fff;
            border: 1px solid #eee;
            border-radius: 10px;
            padding: 1.5rem;
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
            transition: transform 0.3s ease, box-shadow 0.3s ease;
            text-align: left;

            h3 {
                margin-top: 0;
                margin-bottom: 0.8rem;
                font-size: 1.4rem;
                color: $primary-color;
            }

            p {
                font-size: 0.95rem;
                line-height: 1.6;
                color: #666;
            }

            &:hover {
                transform: translateY(-5px);
                box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
            }
        }
    }

    // Pricing Section styling
    .pricing-section {
        margin-top: 2rem;

        .pricing-cards {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
            gap: 2rem;
            margin-bottom: 2rem;
        }

        .pricing-card {
            background: #fff;
            border: 1px solid #e0e0e0;
            border-radius: 12px;
            padding: 1.8rem;
            box-shadow: 0 4px 16px rgba(0, 0, 0, 0.05);
            transition: transform 0.3s ease, box-shadow 0.3s ease;
            text-align: left;

            .package-name {
                font-size: 1.5rem;
                color: $primary-color;
                margin-bottom: 1rem;
                text-align: center;
                position: relative;
                padding-bottom: 0.5rem;

                &:after {
                    content: "";
                    position: absolute;
                    left: 50%;
                    bottom: 0;
                    transform: translateX(-50%);
                    width: 60px;
                    height: 3px;
                    background: $secondary-color;
                    border-radius: 2px;
                }
            }

            .package-details {
                list-style: none;
                padding: 0;
                margin: 0;

                li {
                    margin-bottom: 0.8rem;
                    font-size: 0.95rem;
                    color: #555;
                    line-height: 1.6;

                    strong {
                        color: #333;
                        margin-right: 0.5rem;
                    }
                }
            }

            &:hover {
                transform: translateY(-8px);
                box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
            }
        }

        .final-note {
            font-size: 0.95rem;
            color: #777;
            margin-top: 1rem;
            text-align: center;
            font-style: italic;
        }
    }
}

// Animations
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideIn {
    from {
        width: 0;
    }

    to {
        width: 50px;
    }
}

// Responsive adjustments
@media only screen and (min-width: 768px) {
    .commit-section__text-container {
        padding: 3rem;
    }

    .commitment-title {
        font-size: 2.5rem;
    }

    .text-content {
        font-size: 1.1rem;
    }
}

@media only screen and (min-width: 1220px) {
    .commit-section__text-container {
        margin: 0 10%;
    }

    .commitment-title {
        font-size: 3rem;
    }

    .text-content {
        font-size: 1.2rem;
    }
}
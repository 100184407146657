@use '../../Partials/variables' as *;
@use '../../Partials/mixins' as *;

.booklet {
  .flipbook {
    overflow: hidden;
  }

  .stf__wrapper {
    padding-bottom: 0 !important;
  }

  .btn-block {
    text-align: center;
  }

  .page-flip {
    width: 10rem;
  }

  .flip-block {
    background-color: rgba(128, 128, 128, 0.105);
    padding: 1rem;
  }

  .page-flip-btn {
    margin: 1rem;
    border: 0;
    background-color: $primary-color;
    color: white;
    padding: 0.5rem;
    font-weight: 400;
    border-radius: $border-radius-sm;
    width: 7rem;
    font-size: 0.9rem;
    transition: ease 0.5s;
  }

  .page-open-btn {
    margin-top: 1rem;
    font-size: 0.7rem;
    padding: 0.7rem;
    color: white;
    border-radius: $border-radius-sm;
    border: none;
    background-color: $primary-color;
    transition: ease 0.5s;
  }

  .doc_icon {
    width: 1.5rem;
    margin-right: 0.2rem;
    size: 10px;
    filter: invert(110%) brightness(100%);
  }

  @media only screen and (max-width: 3000px) and (min-width: 1310px) {
    .pdf-block {
      float: right;
    }

    .page-open-btn:hover,
    .page-flip-btn:hover {
      background-color: rgb(194, 30, 30);
    }
  }

  @media only screen and (max-width: 1310px) and (min-width: 600px) {
    .page-flip-btn {
      margin: 0.5rem;
      border: 0;
      transition: ease 0.5s;
    }

    .page-open-btn {
      margin-top: 0.5rem;
      padding: 0.5rem;
    }

    .page-open-btn:hover,
    .page-flip-btn:hover {
      background-color: rgb(194, 30, 30);
    }
  }

  @media only screen and (max-width: 600px) {
    // disable swipe/tap functions on smaller screens to allow zoom (if screen width < 600 it can be assumed the site is being viewed on mobile)
    .flipbook {
      pointer-events: none;
    }

    .page-flip-btn {
      margin: 0.5rem;
      font-size: 0.7rem;

      &:focus {
        background-color: $primary-red;
      }
    }

    .pdf-block {
      display: inline-block;
    }

    .page-open-btn {
      margin: 0.5rem;
      padding: 0.5rem;
    }
  }
}
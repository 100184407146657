@use "../../Partials/mixins" as *;
@use "../../Partials/variables" as *;
@use "../../Partials/animation" as *;

.side-media {
  display: flex;
  @include center;
  position: fixed;
  top: 45%;
  right: 0;
  z-index: 2;
  animation: slide-in-right 0.7s $base-ease;
  transition: all 0.7s $base-ease;

  &--hide {
    transform: translateX(70%);
  }

  &:hover &__button {
    opacity: 1;
  }

  &__list {
    @include flex-column;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  &__button {
    margin: 0.5rem 0.5rem 0 0;
    border: 0;
    background-color: transparent;
    color: rgb(219, 219, 219);
    transition: all 0.5s $base-ease;
    max-width: 3rem;

    @include tablet {
      align-self: center;
    }

    &:hover {
      transform: translateX(-10%);
    }

    &--hide {
      &:hover {
        transform: translateX(10%);
      }
    }
  }
}
@use '../../Partials/variables' as *;
@use '../../Partials/animation' as *;

.board-member {
  &-card {
    border: 0px;
    padding: 0rem 1rem 0rem 1rem;
    text-align: center;
    opacity: 0;

    &.fade-in {
      @include fade-up(50%, 0.75s);
      opacity: 1;
    }
  }

  &-name {
    color: $paragraph-text;
    margin-top: 1rem;
    font-size: 1.6rem;
  }

  &-title {
    margin-top: 0.5rem;
    color: $label-color;
  }

  &-info {
    font-size: 1.1rem;
    margin-top: 1rem;
    text-align: left;
  }

  &-img {
    width: 100%;
    height: 22.5rem;
    object-fit: cover;
    object-position: center;
    overflow: hidden;
  
    // this style is only used if there is no available image for the member
    &__filler {
      @extend .board-member-img;
      object-fit: initial;
      object-position: initial;
    }
  }
}

@use '../../../Partials/mixins' as *;
@use '../../../Partials/variables' as *;

// main div styling


.blogPage {
  margin-left: 10%;
  margin-right: 10%;
  &__image {
    width: 100%;
    height: 25rem;
    object-fit: scale-down;
  }

  // styling for h2
  &__explore {
    text-align: center;
  }

  // styling for h1
  &__title {
    margin-top: 1rem;
    text-align: center;
    line-height: 2rem;
    font-weight: 600;
  }
  &__body{
    margin-left: 2%;
    margin-right: 2%;
    line-height: 2rem;
  }
  &__body h4{
    line-height: 2.5rem;
    margin-bottom: 2rem;
    color: rgb(54, 54, 54);
    font-size: 1vb;
  }
  // styling for nested div
  .small__wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(10rem, 20rem));
    gap: 2rem;
    justify-content: center;
  }
}

.blog-card {
  @include flex-column;
  border: 2px solid $secondary-white;
  transition: 0.5s $base-ease;
  padding: 1rem;
  &__body {
    flex-grow: 1;
  }
  img {
    object-fit: cover;
    height: 50%;
    width: 100%;
    padding-bottom: 1rem;
  }
  &__btn {
    width: 100%;
    text-decoration: none;
    font-weight: bold;
    text-align: center;
    font-size: 1rem;
    border: 2px solid $primary-red;
    padding: 0.5rem;
    background-color: transparent;
    color: $primary-red;
    border-radius: 0.2rem;
    transition: 0.5s $base-ease;
    align-self: end;
    @include tablet {
      padding: 0.75rem;
    }
    &:hover {
      background-color: $primary-red;
      color: white;
    }
  }
  &:hover {
    transform: scale(1.05);
  }
}
